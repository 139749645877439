import React from "react";
import { useSelector } from "react-redux";
// Containers
import MySessionReport from "../../MySessions/MySession/MySessionReport";
import TopBarLayout from "containers/Layout/Topbar/topBar";
// tools
import { navigate } from "gatsby";


const SessionReport = () => {
  // bind state
  const { selectedSession, votes } = useSelector(
    (state) => state.session
  );
  return (
    <React.Fragment>
      <TopBarLayout />
      <MySessionReport session={selectedSession} votes={votes} returnCallback={() => navigate("/s")}/>
    </React.Fragment>
  )
}

export default SessionReport;