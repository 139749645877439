import React from "react";
import PrivateRoute from "components/PrivateRoute";
// Containers
import Report from "containers/Session/Report";
import UserLogged from "../../../containers/userLogged";
const SR = () => {
  return (
    <UserLogged>
      <PrivateRoute component={Report} />
    </UserLogged>
  );
};

export default SR;
